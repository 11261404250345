import { AppPost, AppGet, AppPreView, AppPostDownload } from '@/utils/request';
import Cookies from 'js-cookie';
import { QeList, IGetUserInfoResponse } from './types';

export function getReportList(params: any) {
  return AppGet<any>('/researchReport/getResearchReports', params);
}
export function openPDF(params: any, callback?: any) {
  window.open('/api/minioFile/previewPDF?fileName=' + params.fileName + '&token=' + Cookies.get('_fits_client_'));
}
export function bondNewValuation_list(params: any) {
  return AppPost<any>('/bondNewValuation/list', params);
}
export function downloadExcel(params: any, ExcelName: any, callback?: any) {
  const e = AppPostDownload('/bondNewValuation/exportExcel', params, ExcelName, callback);
  return e;
}

export function depthReport_list(params: any) {
  return AppGet<any>('/depthReport/list', params);
}
export function openPDF2(params: any) {
  window.open('/api/depthReport/downloadReport?filename=' + params.filename + '&token=' + Cookies.get('_fits_client_'));
}
export function openPDF3(params: any) {
  window.open('/api/depthReport/previewReport?filename=' + params.filename + '&token=' + Cookies.get('_fits_client_'));
}
export function getHotSpotsReport(params: any) {
  return AppGet<any>('/researchReport/getHotSpotsReport', params);
}