import { AppPost, AppGet, AppDownload } from '@/utils/request';
import { homeApi } from '@/config/api';
import { QeList, IGetUserInfoResponse } from './types';
import Cookies from 'js-cookie';

export function QErateList(params: any) {
  return AppGet<QeList>('/company/getList', params);
}
export function QEindustry(params: any) {
  return AppGet<QeList>('/common/getTradeTypeList', params);
}
export function QEgetIndustryInfo(params: any) {
  return AppGet<QeList>('/common/getIndustryInfo', params);
}
export function QEregionTree(params: any) {
  //let _e=localStorage.getItem('regionTree');
  // if(_e)return JSON.parse(_e);
  const e = AppGet<QeList>('/common/regionTree', params);
  return e;
}
export function downloadExcel(params: any, ExcelName: any, callback?: any) {
  const e = AppDownload('/compProfile/exportExcel', params, ExcelName, callback);
  return e;
}

export function QeExcel(params: any, ExcelName: any) {
  const e = AppDownload('/company/export', params, ExcelName);
  return e;
}
export function previewPDFQeV2(params: any) {
  window.open('/api/minioFile/previewPdfQeV2?creditCode=' + params.creditCode + '&token=' + Cookies.get('_fits_client_'));
}

export function vote_votesList(params: any) {
  return AppGet<QeList>('/vote/votesList', params);
}
export function vote_votes(params: any) {
  return AppPost<QeList>('/vote/votes', params);
}
