import { message } from 'antd';
import { instance, instanceWW } from './axios';
import qs from 'qs';

export function AppGetWW<T>(url: string, data?: any, callback?: any) {
  return new Promise<T>((resolve, reject) => {
    instanceWW
      .get(url, {
        params: {
          ...data
        },
        paramsSerializer: (params: any) => {
          return qs.stringify(params, { indices: false });
        }
      })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data as T);
        } else {
          reject(res.data.msg || res.data.errMsg);
        }
      })
      .catch((res: any) => {
        if (res && res.response.status == 404) {
          callback(res.response.status)
        }
        reject(new Error(`下载失败: ${res.message || res}`));
      });
  });
}

export function AppPost<T>(url: string, data: any, callback?: any) {
  return new Promise<T>((resolve, reject) => {
    instance
      .post(url, data)
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data as T);
          callback ? callback(res) : null
        } else {
          reject(res.data.msg || res.data.errMsg);
        }
      })
      .catch((err: any) => {
        reject(err.toString());
      });
  });
}

export function AppGet<T>(url: string, data?: any, callback?: any) {
  return new Promise<T>((resolve, reject) => {
    instance
      .get(url, {
        params: {
          ...data
        },
        paramsSerializer: (params: any) => {
          return qs.stringify(params, { indices: false });
        }
      })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data as T);
          callback ? callback(res) : null
        } else {
          reject(res.data.msg || res.data.errMsg);
        }
      })
      .catch((err: any) => {
        // if (err.toString() === 'Error: Request failed with status code 400') {
        //   window.location.href = '/pub/403';
        // }
        reject(err.toString());
      });
  });
}

// 获取图片文件流
export function AppGetBlob<T>(url: string, data?: any, callback?: any) {
  return new Promise<T>((resolve, reject) => {
    instance
      .get(url, {
        params: {
          ...data
        },
        responseType: 'blob',
        paramsSerializer: (params: any) => {
          return qs.stringify(params, { indices: false });
        }
      })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data as T);
          callback ? callback(res) : null
        } else {
          reject(res.data.msg || res.data.errMsg);
        }
      })
      .catch((err: any) => {
        // if (err.toString() === 'Error: Request failed with status code 400') {
        //   window.location.href = '/pub/403';
        // }
        reject(err.toString());
      });
  });
}

function transferUrl(url: string, params?: { [key: string]: string }): string {
  if (!params) {
    return url;
  }

  let result = url;

  let left = url.indexOf('{');
  while (left > -1) {
    const right = result.indexOf('}');
    const propName = result.substring(left + 1, right);
    result = result.replace(`{${propName}}`, params![propName] ? params![propName] : 'null');
    left = result.indexOf('{', right + 1);
  }

  return result;
}

export function AppRestGet<T>(url: string, data?: { [key: string]: string }) {
  const reqURI = transferUrl(url, data);
  return new Promise<T>((resolve, reject) => {
    instance
      .get(reqURI)
      .then((res: any) => {
        if (res.data.code === 200) {
          resolve(res.data.data as T);
        } else {
          reject(res.data.msg || res.data.errMsg);
        }
      })
      .catch((err: any) => {
        // if (err.toString() === 'Error: Request failed with status code 400') {
        //   window.location.href = '/pub/403';
        // }
        // reject(err.toString());
      });
  });
}

export function AppUpload(url: string, data: any) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, {
        headers: 'multipart/form-data'
      })
      .then((res: any) => {
        if (res.data.code === 200) {
          resolve('success');
        }
      })
      .catch((res: any) => {
        reject(res);
      });
  });
}
// 下载文件Get方式
export function AppDownload(url: string, options: any, ExcelName?: any, callback?: any): Promise<void> {
  return new Promise((resolve, reject) => {
    // const defaultOptions = {
    //   responseType: 'blob'
    // };
    // const finalOptions = { ...defaultOptions, ...options };
    instance
      .get(url, {
        params: {
          ...options
        },
        responseType: 'blob'
      },
      )
      .then((res: any) => {
        // console.log(finalOptions)
        const dataUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = dataUrl;
        let filename = '';
        try {
          if (!ExcelName && res.headers['content-disposition']) {
            filename = res.headers['content-disposition']?.split(';')[1]?.split('filename=')[1];
            if (filename?.endsWith('"')) {
              filename = filename.substring(0, filename.length - 1);
            }
            // console.log(decodeURI(filename)) // 需解码防止中文乱码
          }

          link.setAttribute('download', ExcelName ? `${ExcelName}.xlsx` : decodeURI(filename));
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(dataUrl);
          document.body.removeChild(link);
          resolve();
        } catch (res: any) {
          reject(new Error(`下载失败: ${res.message || res}`));
        }
      })
      .catch((res: any) => {
        if (res && res.response.status == 403 || res.response.status == 400 || res.response.status == 500) {
          callback(res.response.status)
        }
        reject(new Error(`下载失败: ${res.message || res}`));
      });
  });
}

// 下载文件POST方式
export function AppPostDownload(url: string, data: any, ExcelName?: any, callback?: any): Promise<void> {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, {
        responseType: 'blob'
      })
      .then((res: any) => {
        // console.log(finalOptions)
        const dataUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = dataUrl;
        let filename = '';
        try {
          if (!ExcelName && res.headers['content-disposition']) {
            filename = res.headers['content-disposition']?.split(';')[1]?.split('filename=')[1];
            if (filename?.endsWith('"')) {
              filename = filename.substring(0, filename.length - 1);
            }
            // console.log(decodeURI(filename)) // 需解码防止中文乱码
          }

          link.setAttribute('download', ExcelName ? `${ExcelName}.xlsx` : decodeURI(filename));

          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(dataUrl);
          document.body.removeChild(link);
          resolve();
        } catch (res: any) {
          reject(new Error(`下载失败: ${res.message || res}`));
        }
      })
      .catch((res: any) => {
        if (res && res.response.status == 403) {
          callback(res.response.status)
        }
        reject(new Error(`下载失败: ${res.message || res}`));
      });
  });
}

export function AppPreView(url: string, options: any, ExcelName: any, callback?: any): Promise<void> {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: {
          ...options
        },
        responseType: 'blob'
      },
      )
      .then((res: any) => {
        // const contentDisposition = res.headers['content-disposition'];
        // console.log(decodeURIComponent(contentDisposition.split("filename=")[1]))
        const blob = new window.Blob([res.data], {
          type: 'application/pdf;charset-UTF-8'
        })
        const href = URL.createObjectURL(blob)
        callback ? callback(res) : null
        window.open(href, '', null)
        URL.revokeObjectURL(href)
      })
      .catch((res: any) => {
        if (res && res.response.status == 403) {
          message.warning("暂无权限")
        }
        reject(new Error(`下载失败: ${res.message || res}`));
      });
  });
}


export function DownloadPackage(url: any, options: any, fileName: any, callback: any) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: {
          ...options
        },
        responseType: 'blob' // 确保返回的数据类型为Blob
      })
      .then((res) => {
        // 处理响应数据
        const blob = new Blob([res.data], {
          type: 'application/x-rar-compressed' // 设置MIME类型为RAR
        });
        // 创建一个链接元素
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName || 'download.rar'; // 设置下载的文件名，扩展名改为.rar
        // 模拟点击链接进行下载
        document.body.appendChild(link);
        link.click();

        // 清理
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href); // 释放URL对象
        // 如果提供了回调函数，则执行
        if (callback) {
          callback(res);
        }
        resolve(""); // 完成Promise
      })
      .catch((error) => {
        // 处理错误
        if (error.response && error.response.status === 403) {
          message.warning("暂无权限");
        } else {
          reject(new Error(`下载失败: ${error.message || error}`));
        }
      });
  });
}