import { AppPost, AppGet, AppPreView, DownloadPackage } from '@/utils/request';
import Cookies from 'js-cookie';
export function getReportList(params: any) {
  return AppGet<any>('/researchReport/getResearchReports', params);
}

export function getCMFReport(params: any) {
  return AppGet<any>('/researchReport/getCMFReport', params);
}
export function openPDF(params: any) {
  window.open('/api/minioFile/previewPDF?fileName=' + params.fileName + '&token=' + Cookies.get('_fits_client_'));
}
export function getCMFopenPDF(params: any) {
  window.open('/api/minioFile/previewPDFCMF?fileName=' + params.fileName + '&token=' + Cookies.get('_fits_client_'));
}

export function minioFile_download(params: any) {
  const link = document.createElement('a');
  link.href = '/api/minioFile/download?fileName=' + params.fileName + '&token=' + Cookies.get('_fits_client_')
  document.body.appendChild(link);
  link.click();
}
// export function minioFile_download(params: any, callback?: any) {
//   return DownloadPackage('/minioFile/download', params, '', callback);
// }
// // https://www.fit-smart.com.cn/api/minioFile/download?fileName=2024