import { AppPost, AppGet, AppDownload } from '@/utils/request';

export function Park_GetParkList(params: any) {
  return AppGet<any>('/park/getParkList', params);
}
export function Park_GetParkCount(params: any) {
  return AppGet<any>('/park/getParkCount', params);
}
export function Park_GetParkRemarkList(params: any) {
  return AppGet<any>('/park/getParkRemarkList', params);
}
export function Park_GetParkDataList(params: any) {
  return AppGet<any>('/park/getParkDataList', params);
}
export function Park_GetParkTargets(params: any) {
  return AppGet<any>('/park/getParkTargets', params);
}
export function Park_GetEngineeringCost(params: any) {
  return AppGet<any>('/park/getEngineeringCost', params);
}
export function Park_GetEngineeringCostYearList(params: any) {
  return AppGet<any>('/park/getEngineeringCostYearList', params);
}