import { AppPost, AppGet, AppPreView } from '@/utils/request';

import {
  SelectedInfoList,
  InformationType,
  PolicyInfoResponse,
  NoticeInfoResponse,
  PolicyTypeResponse
} from './types';

export function getSelectedInfoList(params: any) {
  return AppGet<SelectedInfoList>('/pbVoice/getList', params);
}
export function getInformationType(params: any) {
  return AppGet<InformationType>('/pbVoice/getTypeList', params);
}

export function getPolicyList(params: any) {
  return AppGet<PolicyInfoResponse>('/policy/getList', params);
}
export function getPolicyType(params: any) {
  return AppGet<PolicyTypeResponse>('/policy/getTypeList', params);
}
export function getPolicyIndustry(params: any) {
  return AppGet<any>('/policy/getIndustryist', params);
}
export function getNoticeList(params: any) {
  return AppGet<NoticeInfoResponse>('/enterprise/getAnnouncementDisclosure', params);
}
export function getAnnouncementTypes(params: any) {
  return AppGet<any>('/enterprise/getAnnouncementTypes', params);
}
export function cabin_getPublicOpinList(params: any) {
  return AppGet<any>('cabin/getPublicOpinList', params);
}
export function common_getIndustryInfo2(params: any) {
  return AppGet<any>('common/getIndustryInfo2', params);
}


export function CreditRiskDataGetList(params: any) {
  return AppGet<any>('/creditRiskData/getList', params);
}

// export function CreditRiskDataGetList(params: any) {
//   return AppGet<any>('/creditRiskData/viewPdf', params);
// }


export function openPDF(params: any, callback?: any) {
  return AppPreView('/creditRiskData/viewPdf', params, '', callback);
}


export function enterpriseRecommendation(params: any) {
  return AppGet<any>('/enterpriseRecommendation', params);
}