import { AppPost, AppGet } from '@/utils/request';


//计算器接口
export function CalSecuritiesSearch(params: any) {
    return AppGet<any>('/calSecurities/search', params);
}

export function CalSecuritiesCal(params: any) {
    return AppPost<any>('/calSecurities/cal', params);
}

export function CommonDebSearch(params: any) {
    return AppGet<any>('/common/debSearch', params);
}
